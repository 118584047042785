<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Price
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="price-form">
          <v-text-field
            label="Price *"
            v-model="fields.price"
            type="number"
            step="0.01"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>
          <v-select
            label="Type *"
            v-model="fields.type"
            :items="types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="price-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      price: {},
      fields: {
        price: null,
        type: null,
      },
      types: ["Fixed Price", "Offers Over", "Offers Around", "Guide Price"],
      errors: {},
    };
  },

  methods: {
    open(price = null) {
      if (price !== null) {
        this.price = price;
        this.fields.price = price.price;
        this.fields.type = price.type;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        propertyId: this.$route.params.propertyId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.priceId = this.property.price.id;
      }

      this.$store
        .dispatch("melrose/propertiesStore/savePrice", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.price = {};
      this.fields = {
        price: null,
        type: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
